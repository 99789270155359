import { ADD_CASE, UPDATE_CASE, GET_CASES, GET_CASE, INIT } from '../actions/types';
import { IAction } from '../interfaces/reduxInterface';
import _ from 'lodash';
import { initState, IState } from './index';
export const CaseReducer = (state:any={}, action:IAction)=>{
    switch(action.type)
    {
        case ADD_CASE:
            return{
                ...state.case,
                    [action.payload.id]:action.payload 
            }
        case GET_CASES: 
            return{
                ...state.case, ..._.mapKeys(action.payload,'id')
            }
        case GET_CASE:
            return {
                ...state.case, [action.payload.id]:action.payload
            }
        case UPDATE_CASE:
            return{
                ...state.case, [action.payload.id]:action.payload
            }
        default:
            return state;
    }
}
