import { ADD_ALERT, GET_ALERT, REMOVE_ALERT } from '../actions/types';
import { IAction } from '../interfaces/reduxInterface';
import { IAlert,EAlert } from '../interfaces/alert';

import { initState, IState } from './index';

export const AlertReducer = (state:any={}, action:IAction ) =>{
    switch(action.type){
        case ADD_ALERT:
            return{
                ...state.alert, ...action.payload
            }
        case GET_ALERT:
            return state.alert;

        case REMOVE_ALERT:
            return{
                ...state.alert, ...initState.alert
            }
        default:
            return state;
    }
}