import {
    ADD_AUSDECISSION,
    FETCH_AUSDECISSIONS

} from '../actions/types';

import { IState } from '.';
import { IAction } from '../interfaces/reduxInterface';
import _ from 'lodash';


export const ausDecisionReducer = (state:any={}, action:IAction)=>{

    switch(action.type)
    {
        case ADD_AUSDECISSION:
            
            return{
                ...state, [action.payload.id]: action.payload
            };
        case FETCH_AUSDECISSIONS:
            return {...state.ausDecision, ..._.mapKeys(action.payload, 'id')};
        default:
            return state;
    }

}