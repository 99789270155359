import {
  ADD_AUSDECISSION,
  FETCH_AUSDECISSIONS,
  ADD_RECENT_AUS_RECORD,
  ADD_ALERT,
  LOADING,
} from './types';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import * as queries from '../graphql/queries';
import { IAlert, EAlert, ILoading } from '../interfaces/alert';
import { IAusDecision } from '../interfaces/ausDecsionInterface';

export const addAusDecision =
  (params: IAusDecision, caseNumber: string) => async (dispatch: any) => {
    let alert: IAlert = {
      message: 'Submitting..',
      type: EAlert.warning,
      showAlert: true,
    };

    try {
      dispatch({
        type: ADD_ALERT,
        payload: alert,
      });

      /******* * STEP 1 *********/
      // ADD TO DB
      const result = (await API.graphql(
        graphqlOperation(mutations.createAusDecision, {
          input: params,
        })
      )) as any;

      const { id: ausID } = result.data.createAusDecision;

      dispatch({
        type: ADD_AUSDECISSION,
        payload: result.data.createAusDecision,
      });

      dispatch({
        type: ADD_RECENT_AUS_RECORD,
        payload: result.data.createAusDecision,
      });
      /********** END OF STEP 1 *****/

      /******* * STEP 2 *********/
      // CALL STEP functionn
      //n console.log('CASE NUMBER FROM:', caseNumber, params);

      const stepResult = (await API.graphql(
        graphqlOperation(mutations.processAusDecision, {
          SASIDDecisionModuleName: params.ausDecisionModule,
          CaseID: params.caseId,
          AusDecisionId: ausID,
          CaseNumber: caseNumber,
        })
      )) as any;

      // dispatch({
      //     type: ADD_ALERT,
      //     payload: ausDecisionProcessMessage(stepResult.data.processAusDecision)
      // })

      /********** END OF STEP 2 *****/

      /******* * STEP 3 *********/
      // CALL THE RECORD AGAIN
      const asuDecision = (await API.graphql(
        graphqlOperation(queries.getAusDecision, {
          id: ausID,
        })
      )) as any;

      // console.log('STATUS', asuDecision?.data?.getAusDecision)

      // if (asuDecision?.data?.getAusDecision?.ausDecisionResult) {
      //   await API.graphql(
      //     graphqlOperation(mutations.updateCase, {
      //       input: {
      //         id: params.caseId,
      //         caseStatus: asuDecision?.data?.getAusDecision?.ausDecisionResult,
      //       },
      //     })
      //   );
      // }

      dispatch({
        type: ADD_AUSDECISSION,
        payload: asuDecision.data.getAusDecision,
      });
      /********** END OF STEP 3 *****/
    } catch (err: any) {
      console.log('AusDecision Submit Error:', err);
      alert = {
        message: 'Could not submit, Please try again',
        type: EAlert.error,
        showAlert: true,
      };
      dispatch({
        type: ADD_ALERT,
        payload: alert,
      });
    }
  };

export const fetchAusDecisions =
  (case_id: string, sortDirection: string = 'DESC') =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: LOADING,
        payload: true,
      });

      const result = (await API.graphql(
        graphqlOperation(queries.ausDecisionByDate, {
          caseId: case_id,
          sortDirection: sortDirection,
        })
      )) as any;

      //n console.log('AusDecision Result', result);

      dispatch({
        type: FETCH_AUSDECISSIONS,
        payload: result.data.ausDecisionByDate.items,
      });
    } catch (err: any) {
      console.log('AusDecision', err);
      const alert: IAlert = {
        message: 'Could not load AusDecision',
        showAlert: true,
        type: EAlert.error,
        header: 'Error',
      };
      dispatch({
        type: ADD_ALERT,
        payload: alert,
      });
    }
    dispatch({
      type: LOADING,
      payload: false,
    });
  };

const ausDecisionProcessMessage = (result: any): IAlert => {
  const { cause, isError, status } = result;
  let alert: IAlert = {
    message:
      'Aus Decision process seems to have failed. However it could still be running. Try refreshing the page',
    type: EAlert.warning,
    showAlert: true,
    header: 'Error',
  };
  if (status === 'SUCCEEDED') {
    if (isError) {
      //const myReviver = (key:string, val:any) => key === "errorType" ? val.replace(/\\/g, "\\\\") : val;
      //const msgError = JSON.parse(cause,myReviver);
      //console.log(msgError);
      alert = {
        type: EAlert.error,
        message: 'Aus Decision process error',
        showAlert: true,
        header: 'Error',
      };
    } else {
      alert = {
        type: EAlert.succes,
        message: 'Aus Decision process completed',
        showAlert: true,
        header: 'Success',
      };
    }
  } else if (status === 'FAILED') {
    alert = {
      type: EAlert.error,
      message: cause,
      showAlert: true,
      header: 'Error',
    };
  }

  return alert;
};
