import React from 'react';
import { DataStore } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Divider, Container, Grid, Header } from 'semantic-ui-react';
import { CurrentUser } from './currentUser';
import { SignOut } from './signOut';
import { CurrentOrganization } from './currentOrganization';
import IdleTimeOutHandler from './IdleTimeOutHandler';
import { Auth } from 'aws-amplify';

export const MainHeader = () => {
  const signOut = async () => {
    try {
      await Auth.signOut();
      DataStore.clear();
    } catch (err: any) {
      console.log('sign Out Error', err);
    }
  };

  const handleSignOut = async () => {
    await signOut();
    window.location.reload();
  }

  return (
    <>
      <IdleTimeOutHandler onIdle={handleSignOut} />
      <Header as="h2">
        <Grid columns="equal" stretched>
          <Grid.Row stretched>
            <Grid.Column floated="left">
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Container fluid textAlign="left">
                  <Header
                    style={{
                      height: '80px',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPositionX: 'left',
                      backgroundPositionY: 'center',
                      backgroundImage: `url(/cnycn_logo_tr.png)`,
                    }}
                  ></Header>
                </Container>
              </Link>
            </Grid.Column>
            <Grid.Column only="computer tablet">
              <Container fluid textAlign="center">
                <Link to="/">
                  <Header
                    style={{
                      height: '80px',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPositionX: 'center',
                      backgroundPositionY: 'center',
                      backgroundImage: `url(/logo.png)`,
                    }}
                  ></Header>
                </Link>
              </Container>
            </Grid.Column>
            <Grid.Column floated="right">
              <Container fluid textAlign="right">
                <Header.Subheader>
                  <CurrentUser />
                  <SignOut onSignOut={signOut} />
                </Header.Subheader>
                {/* <CurrentOrganization /> */}
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Header>
      <Divider clearing />
    </>
  );
};
