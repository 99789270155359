import { AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Container, Grid, Header } from 'semantic-ui-react'
import './css/login.css';

const LoginContainer = () => (
    <AmplifyAuthContainer>
        <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" hideSignUp={true}>
            </AmplifySignIn>
        </AmplifyAuthenticator>
    </AmplifyAuthContainer>
)

const logoStyle = {
    position: 'relative',
    width: '402.5px',
    height: '389.09px',
    top: '-8px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundImage: `url(/photo_2021-10-29_19-27-43.jpg)`
};

export const Login = () => (
    <div className="loginPage">
        <Grid columns='equal' stackable textAlign='center' doubling padded='horizontally'>
            <Grid.Row verticalAlign='middle'>
                <Grid.Column only='computer' floated='right' className="leftColumn">
                    <Container>
                        <Header className="amplify-sign-in.hydrated" style={logoStyle}>
                        </Header>
                        {/* <img style={{ height:'389.09px' }} src="photo_2021-10-29_19-27-43.jpg" /> */}
                    </Container>
                </Grid.Column>

                <Grid.Column floated='left' className="rightColumn">
                    <LoginContainer/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
)
