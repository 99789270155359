import { ICognitoUser } from './cognitoUser';
export enum LoanFileStatus {
    IMPORTED = "Imported",
    IN_PROGRESS = "InProgress",
    ERROR = "Error"
}
export interface ILoanFile {
    id?: string;
    caseId: string;
    tenantOrganizationGroup: string,
    fileName: string;
    status?: LoanFileStatus | keyof typeof LoanFileStatus;
    uploadedAt?: string;
    importedAt?: string;
    databaseId?: string;
    uploadedBy?: ICognitoUser;
    urlKey?: string;
    owner?: string;
    updatedAt?: string;
    createdAt?: string;
    loanFileUploadedById?: string;

}