import { Suspense, lazy, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { getOrganization } from './requests';
import { setOrganization } from '../../actions';
import { MainHeader } from '../../components/mainHeader';
import { MainFooter } from '../../components/mainFooter';

const Cases = lazy(() => import('../case/cases'));
const EditCase = lazy(() => import('../case/editCase'));

const Home = ({ setOrganization }: any) => {
  useEffect(() => {
    getOrganizaionFromUser();
  }, []);

  const getOrganizaionFromUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const [tenantGroup] =
      user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];

    const organization = await getOrganization(tenantGroup);

    if (organization) {
      setOrganization(organization);
    }
  };

  return (
    <Container
      fluid
      style={{
        paddingLeft: 75,
        paddingRight: 75,
        paddingTop: 20,
        height: '100vh',
      }}
    >
      <Suspense fallback={<div>Loading data...</div>}>
        <Router>
          <MainHeader />
          <Switch>
            <Route path="/case/:id/:tab?">
              <EditCase />
            </Route>
            <Route path="/">
              <Cases />
            </Route>
          </Switch>
          <MainFooter />
        </Router>
      </Suspense>
    </Container>
  );
};

export default connect(null, { setOrganization })(Home);
