import {
    ADD_LOAN_FILE,
    GET_LOAN_FILES,
    UPDATE_LOAN_FILE_LIST,
    UPDATE_A_LOAN_FILE,
    SET_LOAN_FILE_LIST,
    LOADING,
    ADD_ALERT,
    LOAN_FILE_CURRENT_INGESTED_ADD,
    LOAN_FILE_CURRENT_INGESTED_REMOVE,
    LOAN_FILE_CURRENT_INGESTED_GET
} from './types';
import { Auth, API, graphqlOperation, Storage } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import * as mutations from '../graphql/mutations';
import { ILoanFile, LoanFileStatus } from '../interfaces/loanfile';
import { EAlert, IAlert } from '../interfaces/alert';

export const getLoanFiles = (case_unique: string, sortDirection: string = 'DESC') => async (dispatch: any) => {
    try {
        dispatch({
            type: LOADING,
            payload: true
        })
        const result = await API.graphql(graphqlOperation(queries.loanFileByDate, {
            caseId: case_unique,
            sortDirection: sortDirection
        })) as any;

        //n console.log('Loan File List', result);

        dispatch({
            payload: result.data.loanFileByDate.items,
            type: GET_LOAN_FILES
        })

    } catch (err: any) {
        console.log('Loan File List Error', err);
        const alert: IAlert = {
            message: "Could not load Loan Files",
            type: EAlert.error,
            showAlert: true
        };
        dispatch({
            type: ADD_ALERT,
            payload: alert
        });
    }

    dispatch({
        type: LOADING,
        payload: false
    })

}

export const addLoanFileToStorage = (params: any) => async (dispatch: any) => {
    let alert: IAlert = {
        message: 'Adding Loan File...',
        type: EAlert.warning,
        showAlert: true
    }

    dispatch({
        type: ADD_ALERT,
        payload: alert
    })
    Storage.put(params.fileName, params.file, { contentType: 'application/xml', level: 'private' })
        .then(async (result: any) => {
            const input: ILoanFile = {
                caseId: params.case_unique,
                fileName: params.fileName,
                tenantOrganizationGroup: params.tenantOrganizationGroup,
                loanFileUploadedById: params.loanFileUploadedById,
                status: LoanFileStatus.IN_PROGRESS,
                databaseId: '',
                urlKey: result.key
            };
            await addLoanFile(input);
        }).catch((err: any) => {
            console.log("Loanfile Storege: ", err);
            alert = {
                message: 'Could not store LoanFile',
                type: EAlert.error,
                showAlert: true
            }
            dispatch({
                type: ADD_ALERT,
                payload: alert
            })
        });




}

export const addLoanFile = (params: any) => async (dispatch: any) => { //params:ILoanFile
    let alert: IAlert = {
        message: 'Adding Loan File...',
        type: EAlert.warning,
        showAlert: true
    }
    try {

        dispatch({
            type: ADD_ALERT,
            payload: alert
        })
        //n console.log('Params:', params);
        const resultStorage = await Storage.put(params.fileName, params.file,
            { contentType: 'application/xml', level: "private" });
        const user = await Auth.currentUserInfo();
        const input: ILoanFile = {
            caseId: params.case_unique,
            fileName: params.fileName.split('/').pop() || '',
            status: LoanFileStatus.IN_PROGRESS,
            tenantOrganizationGroup: params.tenantOrganizationGroup,
            loanFileUploadedById: params.loanFileUploadedById,
            owner: params.owner,
            //databaseId: params.LoanFileNumber,
            urlKey: `private/${user.id}/${resultStorage.key}`
            //urlKey:  resultStorage.key
        };

        const result = await API.graphql(graphqlOperation(mutations.createLoanFile, {
            input: input
        })) as any;



        dispatch({
            type: LOAN_FILE_CURRENT_INGESTED_ADD,
            payload: result.data.createLoanFile
        });

        dispatch({
            type: ADD_LOAN_FILE,
            payload: result.data.createLoanFile
        });

        const resultProcess = await API.graphql(graphqlOperation(mutations.processLoanFileIngest, {
            fileID: result.data.createLoanFile.id
        })) as any;

        const { cause,
            isError,
            status
        } = resultProcess.data.processLoanFileIngest;

        /*********** fetch the Loan File Data */

        const loanfileUpdate = await API.graphql(graphqlOperation(queries.getLoanFile, {
            id: result.data.createLoanFile.id
        })) as any;

        dispatch({
            type: UPDATE_A_LOAN_FILE,
            payload: loanfileUpdate.data.getLoanFile
        })



        if (status === 'SUCCEEDED') {
            if (isError) {
                const myReviver = (key: string, val: any) => key === "errorType" ? val.replace(/\\/g, "\\\\") : val;
                const msgError = JSON.parse(cause, myReviver);
                //console.log(msgError);
                alert = {
                    header: 'Loan file ingestion error',
                    type: EAlert.error,
                    message: msgError, //'Loan file ingestion error',
                    showAlert: true
                };

            } else {
                alert = {
                    message: 'Loan file ingestion complete',
                    type: EAlert.succes,
                    showAlert: true
                };
            }
        } else if (status === 'FAILED') {
            // console.log('Loan file ingestion error', cause,
            // isError,
            // status  );
            alert = {
                header: 'Loan file ingestion error',
                message: cause,
                type: EAlert.error,
                showAlert: true
            };
        } else {
            // console.log('Loan file ingestion error', cause,
            // isError,
            // status  );
            alert = {
                //header:'Loan file ingestion error or still running',
                message: 'Loan file ingestion error or still running',
                type: EAlert.warning,
                showAlert: true
            };
        }



    } catch (err: any) {
        console.log('Loan File Add error', err);
        alert = {
            message: 'Could not add LoanFile',
            type: EAlert.error,
            showAlert: true
        }

    }

    dispatch({
        type: ADD_ALERT,
        payload: alert
    })
}

export const getCurrentIngestedLoanFile = () => {
    return {
        type: LOAN_FILE_CURRENT_INGESTED_GET
    }
}

export const updateLoanFileList = (newloanfile: ILoanFile) => {
    return {
        payload: newloanfile,
        type: UPDATE_LOAN_FILE_LIST
    }
}

export const subscriptionOnUpdateLoanFile = () => async (dispatch: any) => {
    try {




        const user = await Auth.currentAuthenticatedUser();
        //n console.log('Update Loan File Subscription', user.username);


        const onUpdate = API.graphql(graphqlOperation(subscriptions.onUpdateLoanFile, {
            owner: user.username

        })) as any;


        onUpdate.subscribe({
            next: (item: any) => {
                //n console.log("Update Loan File Subscription data", item);

                dispatch({
                    payload: item.value.data.onUpdateLoanFile,
                    type: UPDATE_A_LOAN_FILE
                })
            }
        });
        //onCreateLoanFilePromise.unsubscribe();



    } catch (err: any) {
        console.log('Loan Files DB Error(onCreate Subscription):', err);
    }

}