import { ADD_RECENT_AUS_RECORD } from '../actions/types';
import { IAction } from '../interfaces/reduxInterface'

export const UnderProcessAusRecordReducer = (state:any={}, action:IAction) =>{
    switch(action.type)
    {
        case ADD_RECENT_AUS_RECORD:
            return { 
                ...state.underProcessAusRecord, ...action.payload
            };
        default :
            return state;
    }
}