import { GET_LOAN_FILES, 
    UPDATE_LOAN_FILE_LIST, 
    SET_LOAN_FILE_LIST, 
    ADD_LOAN_FILE,
    UPDATE_A_LOAN_FILE
} from '../actions/types';
import _ from 'lodash';
import { initState, IState } from '.';
import { IAction } from '../interfaces/reduxInterface';

export const LoanfilesReducer = (state:any = {}, action:IAction) =>{
    
    switch(action.type){
        case GET_LOAN_FILES:
            
            return {...state.loanFiles,..._.mapKeys(action.payload,'id')};

        case ADD_LOAN_FILE:
            
            return{
                ...state, [action.payload.id]:action.payload
            }
        case UPDATE_A_LOAN_FILE:
            return{
                ...state, [action.payload.id]: action.payload
            }
        
        case UPDATE_LOAN_FILE_LIST:
            return{
                ...state,
                [action.payload.id]:action.payload
            }
        default:
            return state;
    }
}