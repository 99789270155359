import * as queries from '../../../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";
import { IOrganization } from "../../../interfaces/organizations";

export const getOrganization = async (
    tenantOrganizationGroup: string
): Promise<IOrganization | null> => {
    let res = null;

    try {
        const organizationResult = (await API.graphql(
            graphqlOperation(queries.listOrganizations, {
                filter: {
                    tenantOrganizationGroup: {
                        eq: tenantOrganizationGroup,
                    },
                },
            })
        )) as any;

        const result = organizationResult.data.listOrganizations
            .items

        return result.length > 1 ? undefined : result[0];
    } catch (err: any) { }

    return res;
};