import { ADD_CASE, GET_CASES, GET_CASE, UPDATE_CASE, ADD_ALERT,LOADING } from './types';
import { API,Auth,graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { ICase } from '../interfaces/case';
import { IAlert, EAlert } from '../interfaces/alert';


export const addCase = (params:ICase)=> async(dispatch:any)=>{
    try{
        const result = await API.graphql(graphqlOperation(mutations.createCase,params)) as any;
        dispatch({
            type:ADD_CASE,
            payload:result.data.createCase
        });
    }catch(err:any){
        const payload : IAlert = {
            message:'Could not add new case',
            type: EAlert.error,
            showAlert:true
        };
    
        dispatch({
            type:ADD_ALERT,
            payload: payload
        });
    }
}

export const getACase = (caseID:string)=>async(dispatch:any)=>{
    try{
        dispatch({
            type:LOADING,
            payload:true
        })
        //n console.log('Get a Case Parmas', caseID);
        const result = await API.graphql(graphqlOperation(queries.getCase,{
            id:caseID
        })) as any;

        //n console.log('Get a Case', result);

        dispatch({
            type:GET_CASE,
            payload:result.data.getCase
        });

        

    }catch(err:any){
        console.log('Fetch A CASE Error:', err);
        const payload : IAlert = {
            message:"Could not load Case",
            type: EAlert.error,
            showAlert:true
        };
        
        dispatch({
            type:ADD_ALERT,
            payload: payload
        });

        
    }

    dispatch({
        type:LOADING,
        payload:false
    })

    
    
}

export const getCases = (sortDirection:string='DESC') => async (dispatch:any) =>{
    try{
        dispatch({
            type:LOADING,
            payload:true
        })
        let result :any  = {};
        // const user = await Auth.currentAuthenticatedUser();
        // const group = user.signInUserSession.accessToken.payload["cognito:groups"];
         
        // if(group && (group === 'lenderOrgLimitedUser' && group === 'lenderOrgSuperUser') )
        // {
        //     result = await API.graphql(graphqlOperation(queries.casesByOrgByUpdateAt,{
        //         sortDirection: sortDirection,
        //         caseOrganizationId: user.attributes['custom:organizationID']
        //     })) as any;
            
        //     dispatch({
        //         type:GET_CASES,
        //         payload: result.data.casesByOwner.items
        //     });
        // }else{
        //     result = await API.graphql(graphqlOperation(queries.listCases)) as any;
        
        //     dispatch({
        //         type:GET_CASES,
        //         payload: result.data.listCases.items
        //     });
        // }
        result = await API.graphql(graphqlOperation(queries.listCases)) as any;
        
        dispatch({
            type:GET_CASES,
            payload: result.data.listCases.items
        });

    }catch(err:any){

        console.log('Fetch CASES Error:', err);
        
        const payload : IAlert = {
            message:'Could not load cases',
            type: EAlert.error,
            showAlert:true
        };
        
        dispatch({
            type:ADD_ALERT,
            payload: payload
        });
    }
    
    dispatch({
        type:LOADING,
        payload:false
    })
}

export const updateCase =(param:ICase)=> async (dispatch:any )=>{

    

    try{
        
        let alert:IAlert={
            message:'Updating Case...',
            type:EAlert.warning,
            showAlert:true
        }

        dispatch({
            type:ADD_ALERT,
            payload: alert
        })
        const result  = await API.graphql(graphqlOperation(mutations.updateCase, {
            input:param
        })) as any;

        dispatch({
            type:UPDATE_CASE,
            payload:result.data.updateCase,

        });
        alert = {
            message: "Update Success",
            type:EAlert.succes,
            showAlert:true
        }
        dispatch({
            type:ADD_ALERT,
            payload:alert
        })

    }catch(err:any){

        const payload : IAlert = {
            message:err,
            type: EAlert.error,
            showAlert:true
        };
    
        dispatch({
            type:ADD_ALERT,
            payload: payload
        });

    }
    

}