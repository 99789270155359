export enum EAlert {
  error,
  succes,
  warning,
}
export enum ECaseAlerts {
  noTenantGroups = 'Can’t create a case - user is not a member of any Tenant organization group',
  manyTenantGroups = 'Can’t create a case - user is a member of more than one Tenant organization group, new Case can not be created. We don’t know which Tenant Organization to assign it to',
}
export interface IAlert {
  message: string | null;
  type: EAlert | string | null;
  header?: string | null;
  showAlert: boolean;
}
export interface ILoading {
  loading: boolean;
}
