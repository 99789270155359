import { SET_ORGANIZATION } from '../actions/types';
import { IAction } from '../interfaces/reduxInterface';

export const OrganizationReducer = (state: any = {}, action: IAction) => {
    switch (action.type) {
        case SET_ORGANIZATION:
            return action.payload;
        default:
            return state;
    }
}