import {
    LOAN_FILE_CURRENT_INGESTED_GET,
    LOAN_FILE_CURRENT_INGESTED_ADD,
    LOAN_FILE_CURRENT_INGESTED_REMOVE 
} from '../actions/types'
import { IAction } from '../interfaces/reduxInterface';
import { initState, IState } from './';

export const UnderProcessLoanFileReducer = (state:any={}, action:IAction) =>{
    switch(action.type){
        case LOAN_FILE_CURRENT_INGESTED_ADD:
            
            return { ...state.loanFileCurrentIngested,...action.payload};
        case LOAN_FILE_CURRENT_INGESTED_GET:
                return state.loanFileCurrentIngested;
        case LOAN_FILE_CURRENT_INGESTED_REMOVE:
            
            return { ...state, loanFileCurrentIngested: null};

        default :
            return state;
    }
}