import { combineReducers } from 'redux';
import { IAlert, EAlert, ILoading } from '../interfaces/alert';
import { LoadingReducer } from './loadingReducer';
import { AlertReducer } from './AlertReducer';
import { CaseReducer } from './caseReducer';
import { OrganizationReducer } from './organization';
import { LoanfilesReducer } from './loanfilesReducer';
import { UnderProcessLoanFileReducer } from './UnderProcessLoanFileReducer';
import { ausDecisionReducer } from './ausDecisionReducer';
import { UnderProcessAusRecordReducer } from './underProcessAusRecordReducer';
import { FreeFormApiReducer } from './freeFormApiReducer';


export interface IState {
    alert?: IAlert,
    loading?: ILoading,
    case?: any,
    loanFiles?: any,
    loanFileCurrentIngested?: any,
    ausDecision?: any
}


const initAlert: IAlert = {
    message: null,
    type: null,
    showAlert: false,
    header: null
}
const initLoading = {
    loading: true
}

export const initState: IState = {
    alert: initAlert,
    loading: initLoading,
    case: [],
    loanFiles: [],
    loanFileCurrentIngested: null,
    ausDecision: null
}

export default combineReducers({
    organization: OrganizationReducer,
    loanfiles: LoanfilesReducer,
    cases: CaseReducer,
    loading: LoadingReducer,
    alert: AlertReducer,
    loanFileCurrentIngested: UnderProcessLoanFileReducer,
    ausDecision: ausDecisionReducer,
    underProcessAusRecord: UnderProcessAusRecordReducer,
    freeFormApiData: FreeFormApiReducer,
})