import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import moment from 'moment';

const timeOutInterval = 60000 * 60;

type IdleTimeOutHandlerProps = {
  onActive?: () => void;
  onLogout?: () => void;
  onIdle?: () => void;
};

const IdleTimeOutHandler = (props: IdleTimeOutHandlerProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isLogout, setLogout] = useState(false);
  let timer: any = undefined;
  const events = ['click', 'load', 'keydown'];
  const eventHandler = () => {
    if (!isLogout) {
      localStorage.setItem(
        'lastInteractionTime',
        moment().format('MM-DD-YYYY h:mm:ss')
      );
      if (timer) {
        if (props.onActive) props.onActive();
        startTimer();
      }
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, [timer]);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      let lastInteractionTime = localStorage.getItem('lastInteractionTime');
      const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
      if (isLogout) {
        clearTimeout(timer);
      } else {
        // @ts-ignore:next-line
        if (diff._milliseconds < timeOutInterval) {
          startTimer();
          if (props.onActive) props.onActive();
        } else {
          if (props.onIdle) props.onIdle();
        //   setShowModal(true);
        }
      }
    }, timeOutInterval);
  };
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const handleContinueSession = () => {
    setShowModal(false);
    setLogout(false);
  };
  const handleLogout = () => {
    removeEvents();
    clearTimeout(timer);
    setLogout(true);
    if (props.onLogout) props.onLogout();
    setShowModal(false);
  };

  return (
    <div>
      <Modal size="tiny" open={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header>
          <div>
            <h4>{'Are you still there'}</h4>
          </div>
        </Modal.Header>
        <Modal.Content>You will get timed out. You want to stay?</Modal.Content>
        <Modal.Actions>
          <Button negative onClick={handleLogout}>
           Logout
        </Button>

        <Button basic onClick={handleContinueSession}>
          Stay
        </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default IdleTimeOutHandler;
