import { LOADING } from '../actions/types';
import { IAction } from '../interfaces/reduxInterface';

import { initState, IState } from './index';

export const LoadingReducer =(state:any={ loading: true}, action:IAction)=>{
    
    switch(action.type){
        case LOADING:
            const loading = action.payload === null ? true: action.payload
            
            return {
                ...state, loading
            }
        default:
            return state;
    }
}