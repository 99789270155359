export const INIT = '@@INIT';
export const LOADING = 'LOADING';

export const GET_LOAN_FILES = 'GET_LOAN_FILES';
export const SET_LOAN_FILE_LIST = 'SET_LOAN_FILE_LIST';
export const UPDATE_LOAN_FILE_LIST = 'UPDATE_LOAN_FILE_LIST';
export const UPDATE_A_LOAN_FILE = 'UPDATE_A_LOAN_FILE';
export const ADD_LOAN_FILE = 'ADD_LOAN_FILE';
export const LOAN_FILE_CURRENT_INGESTED_ADD = 'LOAN_FILE_CURRENT_INGESTED_ADD';
export const LOAN_FILE_CURRENT_INGESTED_REMOVE = 'LOAN_FILE_CURRENT_INGESTED_REMOVE';
export const LOAN_FILE_CURRENT_INGESTED_GET = 'LOAN_FILE_CURRENT_INGESTED_GET';

export const ADD_CASE = 'ADD_CASE';
export const GET_CASES = 'GET_CASES';
export const GET_CASE = 'GET_CASEES';
export const UPDATE_CASE = 'GET_CASEES';

export const SET_ORGANIZATION = 'SET_ORGANIZATION';

export const GET_ALERT = 'GET_ALERT';
export const ADD_ALERT = 'GET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const ADD_AUSDECISSION = 'ADD_AUSDECISSION';
export const FETCH_AUSDECISSIONS = 'FETCH_AUSDECISSIONS';
export const ADD_RECENT_AUS_RECORD = 'ADD_RECENT_AUS_RECORD';

export const SET_FREE_FORM_DATA = 'SET_FREE_FORM_DATA';


