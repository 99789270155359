import { SET_FREE_FORM_DATA } from '../actions/types';
import { IAction } from '../interfaces/reduxInterface';

export const FreeFormApiReducer = (state: any = {}, action: IAction) => {
    switch (action.type) {
        case SET_FREE_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
}