import React from 'react';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

interface UserInfo {
  name?: string;
  email?: string;
  groups?: string[];
}

export const getUser = async (): Promise<UserInfo> => {
  const user = await Auth.currentAuthenticatedUser();

  return {
    name: user.getUsername(),
    email: user.attributes.email,
    groups: user.signInUserSession.accessToken.payload['cognito:groups'],
  };
};

export const CurrentUser = () => {
  const [user, setUser] = useState<UserInfo>({});

  const fetchUser = async () => {
    const currentUser = await getUser();
    setUser(currentUser);
  };

  const { t } = useTranslation();

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchUser();
  }, []);

  return <span>{t('loggedIn', { name: user.name })}</span>;
};
