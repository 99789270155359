import { ADD_ALERT, GET_ALERT, REMOVE_ALERT } from './types';
import { IAlert } from '../interfaces/alert';
export const addAlert = (param: IAlert)=>async (dispatch:any) =>{
    dispatch({
        type:ADD_ALERT,
        payload:param
    })
}
export const getAlert = ()=> async (dispatch:any) =>{
    dispatch({
        type:GET_ALERT
        
    })
}

export const removeAlert = ()=>async (dispatch:any)=>{
    dispatch({
        type:REMOVE_ALERT
    })
}